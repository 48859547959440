<template>
  <div class="password">
    <div class="title_a">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,17C10.89,17 10,16.1 10,15C10,13.89 10.89,13 12,13A2,2 0 0,1 14,15A2,2 0 0,1 12,17M18,20V10H6V20H18M18,8A2,2 0 0,1 20,10V20A2,2 0 0,1 18,22H6C4.89,22 4,21.1 4,20V10C4,8.89 4.89,8 6,8H7V6A5,5 0 0,1 12,1A5,5 0 0,1 17,6V8H18M12,3A3,3 0 0,0 9,6V8H15V6A3,3 0 0,0 12,3Z" /></svg>
      Changer mon mot de passe</div>
  
    <div class="bloc">

      <el-form :model="ruleForm" @submit.native.prevent="change_password" :rules="rules" ref="ruleForm">
        <el-form-item label="Mot de passe actuel" prop="old_password">
          <el-input type="password" v-model="ruleForm.old_password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="Nouveau mot de passe" prop="new_password">
          <el-input type="password" v-model="ruleForm.new_password" autocomplete="off"></el-input>
        </el-form-item>
        
        <button_plus :rounded="true" color="orange" txt="Modifier mon mot de passe" :click_not_can="click_not_can"></button_plus>
      </el-form>
    </div>
      
  </div>
</template>

<script>
export default {
  name: "password",
  data() {
    return {
      click_not_can: true,
      ruleForm: {
        new_password: '',
        old_password: ''
      },
      rules: {
        old_password: [
          { required: true, message: 'Vous devez entrer votre ancien mot de passe', trigger: 'change' },
        ],
        new_password: [
          { required: true, message: 'Vous devez entrer un nouveau mot de passe', trigger: 'change' },
          { min: 10, message: 'Minimum 10 caractères', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    change_password() {
      console.log('valid')
      this.$refs['ruleForm'].validate((valid) => {
        console.log(valid)
        if (valid) {
          this.$http.post('/user/password/change', { old_password: this.ruleForm.old_password, new_password: this.ruleForm.new_password }).then(() => {
            this.$refs['ruleForm'].resetFields();
            this.$notify({
              title: 'Mot de passe changé avec succès', offset: this.$notifyOffest, type: 'success',
            });
          }).catch(e => this.$message({ message: e.response.data, type: 'error' }) ); 
        }
      });
    }
  }
}
</script>

<style scoped>

  .bloc { background: #fff; padding: 16px; margin-top: 6px; }

</style>